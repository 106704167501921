import { useContext, useState } from "react";
import {
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineUser,
} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteTokens } from "../utils/authStore";
import { UserContext } from "../layouts/dashboardLayout";

const Navbar = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const { user, pages, fbProfile, instagramAccounts } = useContext(UserContext);
  return (
    <div>
      <div className="fixed w-full z-50">
        <div className="bg-white w-full shadow  px-8 flex justify-between items-center pt-6">
          <div className="flex items-start ">
            <img className="h-9" src="/Kwanza 1.svg" />
            <div className="flex ml-12 space-x-4">
              {[
                {
                  title: "Create a campaign",
                  path: "/create-campaign?tab=setup",
                },
                { title: "Campaigns Dashboard", path: "/" },
              ].map((item) => {
                return (
                  <div
                    className={`${
                      pathname == item.path.split("?tab=")[0]
                        ? "border-primary "
                        : "border-transparent"
                    } border-b-4 pb-4 text-lg px-5  cursor-pointer rounded`}
                    onClick={() => {
                      navigate(item.path);
                    }}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex space-x-2 items-center text-sm pb-2">
            <h1>{user.name}</h1>
            <div className=" relative">
              <div
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
                className="size-10 cursor-pointer rounded-full font-medium flex justify-center items-center bg-gray-100"
              >
                {user.name.split(" ").map((item) => item[0])}
              </div>
              {showMenu && (
                <div className="absolute w-48 right-0 bg-white rounded-lg font-medium px-8 shadow py-4 space-y-3 ">
                  <div
                    onClick={() => {
                      navigate("/my-profile");
                      setShowMenu(false);
                    }}
                    className="flex space-x-2  items-center cursor-pointer text-gray-500 hover:text-gray-600 "
                  >
                    <AiOutlineUser className="text-lg" />
                    <h1>My Account</h1>
                  </div>
                  <div
                    onClick={() => {
                      deleteTokens();
                      navigate("/login");
                    }}
                    className="flex space-x-2  items-center cursor-pointer text-gray-500 hover:text-gray-600 "
                  >
                    <AiOutlineLogout className="text-lg" />
                    <h1>Logout</h1>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
