const { getTokens } = require("../utils/authStore");
const { app } = require("./authController");

export const getMyInfo = () => {
  return app.get("/users/me", {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const storeFacebookToken = (data) => {
  return app.post("/users/store-token", data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getFacebookUrl = ({ redirect }) => {
  return app.get(`/users/fb-login-link/?redirect=${redirect}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editUserInfo = (uuid, data) => {
  return app.patch(`/users/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const getUsers = (path) => {
  return app.get(`/users/${path}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const getUser = (uuid) => {
  return app.get(`/users/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
export const editUser = (uuid, data) => {
  return app.patch(`/users/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const registerUser = (data) => {
  return app.post(`/users/`, data, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};

export const deleteUser = (uuid) => {
  return app.delete(`/users/${uuid}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
