import { getTokens } from "../utils/authStore";
import { app } from "./authController";

export const getUserPosts = async (pageId, pageAccessToken) => {
  return await app.get(
    `/meta/page-posts/?pageId=${pageId}&pageAccessToken=${pageAccessToken}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens()}`,
      },
    }
  );
};
export const getUserPageInsights = async (pageId, pageAccessToken) => {
  return await app.get(
    `/meta/page-insights/?pageId=${pageId}&pageAccessToken=${pageAccessToken}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens()}`,
      },
    }
  );
};
export const getInstagramPosts = async (
  instagramAccountId,
  userAccessToken
) => {
  return await app.get(
    `/meta/instagram-posts/?instagramAccountId=${instagramAccountId}&userAccessToken=${userAccessToken}`,
    {
      headers: {
        Authorization: `Bearer ${getTokens()}`,
      },
    }
  );
};
export const getAdIntrests = async (keyword) => {
  return await app.get(`/meta/ad-interests/?keyword=${keyword}`, {
    headers: {
      Authorization: `Bearer ${getTokens()}`,
    },
  });
};
