import { useContext, useEffect, useState } from "react";
import TextForm from "../components/textForm";
import { UserContext } from "../layouts/dashboardLayout";
import SelectForm from "../components/selectForm";
import { getBusinessCategories } from "../controllers/businessCategoryController";
import { editUser, getFacebookUrl } from "../controllers/userController";
import toast from "react-hot-toast";
import Loader from "../components/loader";
import { FaFacebook } from "react-icons/fa";
import { getUserPageInsights } from "../controllers/metaController";

const MyProfile = () => {
  const { user, fbProfile, pages, refresh, setRefresh } =
    useContext(UserContext);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [tab, setTab] = useState(0);
  const [selectedItem, setSelectedItem] = useState(100);
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    getBusinessCategories().then((response) => {
      const data = response.data.body;
      console.log(data);
      setBusinessCategories(data);
    });
  }, []);
  useEffect(() => {
    if (pages.length > 0) {
      getUserPageInsights(pages[0].id, pages[0].access_token).then((res) => {
        console.log(res.data);
        setInsights(res.data.body);
        setFetching(false);
      });
    } else {
      setFetching(false);
    }
  }, [pages]);
  return fetching == false ? (
    <div>
      <div>
        <h1 className="text-2xl font-bold">My Account</h1>
        <p className="text-sm text-gray-500">
          View and manage you information here
        </p>
        <div className="flex space-x-4 mt-4 pb-0 text-sm ">
          {["Page Insights", "Profile information"].map((item, index) => {
            return (
              <div
                onClick={() => {
                  setTab(index);
                }}
                className={`${
                  index == tab
                    ? "border-primary text-dark font-semibold pb-2"
                    : "border-transparent text-gray-400"
                } border-b-2 cursor-pointer`}
              >
                {item}
              </div>
            );
          })}
        </div>
        {tab == 0 && (
          <div className=" mt-2 space-y-2">
            {pages.length < 1 ? (
              <div className=" space-y-2 flex flex-col ">
                <label className=" text-dark text-sm">Facebook Account</label>
                <button
                  onClick={() => {
                    getFacebookUrl({
                      redirect: "https://sme.kwanza.io/my-profile",
                    }).then((res) => {
                      const url = res.data.body.loginUrl;
                      window.location = url;
                    });
                  }}
                  className="px-4 py-4 flex items-center w-4/12  justify-center space-x-2 bg-blue-500 hover:bg-opacity-90 transition-all duration-200 text-white text-sm  font-semibold rounded-lg"
                >
                  <span>
                    <FaFacebook className="text-lg" />
                  </span>
                  <p>Login with Facebook</p>
                </button>
              </div>
            ) : (
              <div className=" space-y-2 flex flex-col">
                <label className=" text-dark text-sm">Facebook Account</label>
                <div className="flex space-x-8 items-center">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <img
                        src={fbProfile.picture.data.url}
                        className="size-8 rounded-full"
                      />
                    </div>
                    <div>
                      <p className="font-semibold">{fbProfile.name}</p>

                      <p className="text-sm text-gray-500">
                        Page: {pages[0].name}
                      </p>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      editUser(user.uuid, { facebookAccessToken: null }).then(
                        (res) => {
                          toast.success("Logged out successfully");
                          setRefresh(refresh + 1);
                        }
                      );
                    }}
                    className="text-red-400 bg-red-50 py-1 rounded-lg px-6 cursor-pointer text-sm font-bold"
                  >
                    Logout
                  </div>
                </div>
                <p className="text-sm mt-12 font-bold">Insights</p>
                <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4 ">
                  {insights.map((item, index) => {
                    return (
                      <div className="shadow p-4 hover:shadow-lg rounded  transition-all duration-20 cursor-pointer">
                        <p className="text-xl 0 font-semibold ">
                          {item.values[0].value}
                        </p>
                        <p>{item.title}</p>
                        <p
                          className={`text-sm ${
                            selectedItem != index
                              ? "line-clamp-2"
                              : "line-clamp-none"
                          } text-gray-500`}
                        >
                          {item.description}
                        </p>
                        <button
                          onClick={() => {
                            if (index == selectedItem) {
                              setSelectedItem(100);
                            } else {
                              setSelectedItem(index);
                            }
                          }}
                          className="text-sm text-primary"
                        >
                          {selectedItem == index ? "Minimize" : "Read All"}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        {tab == 1 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log(e.target.category.value);
              setLoading(true);
              const payload = {
                name: e.target.name.value,
                businessCategoryId: e.target.category.value,
              };
              editUser(user.uuid, payload).then((res) => {
                toast.success("Saved successfully");
                setLoading(false);
              });
            }}
          >
            <div className="w-4/12 space-y-2 mt-4">
              <TextForm
                name={"name"}
                defaultValue={user.name}
                label={"Username"}
              />
              <SelectForm
                name={"category"}
                values={businessCategories.map((item) => item.id)}
                options={businessCategories.map((item) => item.name)}
                defaultValue={user.BusinessCategoryId}
                label={"Business Category"}
              />
              <TextForm
                value={user.phone}
                disabled={true}
                label={"Phone number"}
              />
            </div>
            <button className="text-sm py-3 px-4 font-bold w-36 text-center bg-primary rounded-lg text-white mt-4">
              {loading ? "Saving..." : "Save Changes"}
            </button>
          </form>
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default MyProfile;
