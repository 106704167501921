import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import DashboardLayout from "./layouts/dashboardLayout";
import SetupPage from "./pages/campaignCreationSteps/setupPage";
import CreateCampaignPage from "./pages/createCampaignPage";
import LoginPage from "./pages/auth/loginPage";
import RegisterPage from "./pages/auth/registerPage";
import ConfirmPage from "./pages/auth/confirmPage";
import { Toaster } from "react-hot-toast";
import CampaignReport from "./pages/campaignReport";
import MyProfile from "./pages/myProfile";

function App() {
  return (
    <div className="light">
      <Toaster position="top-right" />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/confirm/:identifier" element={<ConfirmPage />} />
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/campaign-report" element={<CampaignReport />} />
            <Route path="/create-campaign" element={<CreateCampaignPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
