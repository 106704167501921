import { useContext, useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  getInstagramPosts,
  getUserPosts,
} from "../../controllers/metaController";
import { UserContext } from "../../layouts/dashboardLayout";
import { IoCheckbox } from "react-icons/io5";
import { BsCheck } from "react-icons/bs";
import {
  editMetaCampaign,
  getLatestMetaCampaign,
} from "../../controllers/campaignController";
import toast from "react-hot-toast";
import Loader from "../../components/loader";

const AdsPage = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [instagramPosts, setInstagramPosts] = useState([]);

  const { user, fbProfile, pages, instagramAccounts } = useContext(UserContext);
  const [selectedPost, setSelectedPost] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Facebook");
  useEffect(() => {
    loadCampaign();
  }, []);
  const loadCampaign = () => {
    getLatestMetaCampaign().then((response) => {
      setLoading(false);
      const data = response.data.body;
      setSelectedPost(data.ads);
      if (data) {
        setCampaign(data);
      } else {
        navigate("/create-campaign/?tab=setup");
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    getUserPosts(pages[0].id, pages[0].access_token).then((response) => {
      setPosts(response.data.body);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    getInstagramPosts(instagramAccounts[0].id, user.facebookAccessToken).then(
      (response) => {
        console.log(response.data.body);
        setInstagramPosts(response.data.body);
        setLoading(false);
      }
    );
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div>
      <div>
        <div>
          <h1 className="text-lg font-semibold mb-3">
            Select a post to promote{" "}
          </h1>
        </div>
      </div>
      <div className="flex space-x-2">
        {Object.values(campaign.targetChannels).map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelectedTab(item);
              }}
              className={`border-b-2 ${
                selectedTab == item ? "border-primary" : "border-transparent"
              } border-b-2 pb-2 cursor-pointer`}
            >
              {item}
            </div>
          );
        })}
      </div>
      {selectedTab == "Facebook" && (
        <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4">
          {posts.map((item) => {
            return (
              <div className="rounded bg-white p-2 border border-slate-200 mt-2">
                <img
                  className="h-80 object-contain w-full bg-gray-100 "
                  src={
                    item.media_type == "VIDEO"
                      ? item.thumbnail_url
                      : item.full_picture
                  }
                />

                <div className="flex justify-between items-center">
                  <div>
                    {/* <h1 className="text-sm mt-1">From {pages[0].name}</h1> */}
                    <p className=" text-gray-500 line-clamp-2 ">
                      {item.message}
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setSelectedPost(item);
                      }}
                      className={`${
                        selectedPost?.id == item.id
                          ? "bg-primary text-white font-bold"
                          : "bg-background"
                      } py-1 rounded-md px-3 text-xs`}
                    >
                      {selectedPost?.id == item.id ? "Selected" : "Select"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {selectedTab == "Instagram" && (
        <div className="grid grid-cols-3 2xl:grid-cols-4 gap-4">
          {instagramPosts.map((item) => {
            return (
              <div className="rounded bg-white p-2 border border-slate-200 mt-2">
                <img
                  className="h-80 object-contain w-full bg-gray-100 "
                  src={
                    item.media_type == "VIDEO"
                      ? item.thumbnail_url
                      : item.media_url
                  }
                />
                <div className="flex justify-between items-center">
                  <div>
                    {/* <h1 className="text-sm mt-1">From {pages[0].name}</h1> */}
                    <p className=" text-gray-500 line-clamp-2 ">
                      {item.caption}
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setSelectedPost(item);
                      }}
                      className={`${
                        selectedPost?.id == item.id
                          ? "bg-primary text-white font-bold"
                          : "bg-background"
                      } py-1 rounded-md px-3 text-xs`}
                    >
                      {selectedPost?.id == item.id ? "Selected" : "Select"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-end space-x-2">
        <button
          onClick={() => {
            navigate("/create-campaign?tab=target");
          }}
          className="px-4 py-3 bg-gray-100 cursor-pointer  text-dark  text-sm mt-12 font-semibold rounded-lg"
        >
          Previous
        </button>
        <button
          onClick={() => {
            if (selectedPost) {
              const payload = {
                ads: {},
              };
              payload.ads = selectedPost;
              editMetaCampaign(campaign.uuid, payload).then((res) => {
                toast.success("Updated successfully");
              });
              console.log(payload);
              navigate("/create-campaign?tab=budget");
            } else {
              toast.error("Post is not selected");
            }
          }}
          className="px-4 py-3 bg-primary text-white text-sm mt-12 font-semibold rounded-lg"
        >
          Save & Continue
        </button>
      </div>
    </div>
  );
};

export default AdsPage;
