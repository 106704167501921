import {
  AiOutlineCheck,
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineUser,
} from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useGetParams } from "../utils/useGetParams";
import { createContext, useEffect, useState } from "react";
import { deleteTokens, getTokens } from "../utils/authStore";
import { getMyInfo, storeFacebookToken } from "../controllers/userController";
import Loader from "../components/loader";
import {
  getFacebookAccessToken,
  storeFacebookAccessToken,
} from "../utils/localStorage";
import Navbar from "../components/navbar";
export const UserContext = createContext();
const DashboardLayout = () => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const [user, setUser] = useState({});

  const [loading, setLoading] = useState(true);
  const [instagramAccounts, setInstagramAccounts] = useState([]);
  const [fbProfile, setFBProfile] = useState({});
  const [pages, setPages] = useState({});
  const [refresh, setRefresh] = useState(0);
  const hash = window.location.hash;
  const hashParams = new URLSearchParams(hash.slice(1));
  const access_token = hashParams.get("access_token");
  useEffect(() => {
    if (access_token) {
      if (access_token !== getFacebookAccessToken()) {
        storeFacebookToken({ token: access_token });
        storeFacebookAccessToken(access_token);
        setRefresh(refresh + 1);
      }
    }
  }, [access_token]);
  useEffect(() => {
    const token = getTokens();
    if (token) {
      getMyInfo()
        .then((response) => {
          const data = response.data.body;
          console.log(data);
          setUser(data.userProfile);
          setPages(data.pages);
          setFBProfile(data.fbProfile);
          setInstagramAccounts(data.instagramAccounts);
          setLoading(false);
        })
        .catch((e) => {
          deleteTokens();
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, [refresh]);

  return loading ? (
    <Loader />
  ) : (
    <UserContext.Provider
      value={{ user, pages, fbProfile, instagramAccounts, refresh, setRefresh }}
    >
      <div>
        <div>
          <Navbar />
          <div className=" min-h-screen w-screen pb-20 bg-gray-50 pt-24 ">
            <div className="flex flex-col w-10/12 mx-auto  p-4  rounded-lg ">
              <div className="  bg-white min-h-80  rounded  p-6">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default DashboardLayout;
