import { useEffect, useState } from "react";
import { sendCode } from "../../controllers/authController";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import TextForm from "../../components/textForm";
import { registerUser } from "../../controllers/userController";
import { getBusinessCategories } from "../../controllers/businessCategoryController";
import SelectForm from "../../components/selectForm";

const RegisterPage = () => {
  const [Loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [businessCategories, setBusinessCategories] = useState([]);
  useEffect(() => {
    getBusinessCategories().then((response) => {
      const data = response.data.body;
      console.log(data);
      setBusinessCategories(data);
    });
  }, []);
  const navigate = useNavigate();
  return (
    <div className="w-screen min-h-screen bg-background  flex justify-center items-center text-dark">
      <div className="w-10/12 md:w-4/12 2xl:w-3/12 rounded-xl bg-white flex shadow-lg">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const payload = {
              role: "publisher",
              name: e.target.name.value,
              business_category_uuid: e.target.category.value,
              phone: e.target.phone.value,
            };
            console.log(payload);
            setLoading(true);
            registerUser(payload)
              .then((data) => {
                toast.success("Sent successfully");
                navigate(`/confirm/${payload.phone}`);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                const message = err.response.data.message;
                toast.error(message);
              });
          }}
          className="w-full py-12  flex flex-col items-center px-8 md:px-10 justify-center"
        >
          <img className="h-9" src="/Kwanza 1.svg" />

          <h1 className="font-semibold text-lg md:text-lg mb-1 mt-3">
            Create an account
          </h1>
          <p className="text-sm text-gray-500 ">
            Fill the form below to register
          </p>
          <div className="space-y-2 mt-4 w-full">
            <div className="flex flex-col space-y-2 w-full">
              <TextForm
                name={"name"}
                placeholder={"Enter full name"}
                label={"Business Name"}
              />
              <SelectForm
                name={"category"}
                values={businessCategories.map((item) => item.uuid)}
                options={businessCategories.map((item) => item.name)}
                label={"Business category"}
              />
              <TextForm
                name={"phone"}
                placeholder={"Enter phone number"}
                label={"Phone number"}
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-primary py-3 transition-all w-full font-semibold rounded-lg mt-4 text-white hover:bg-opacity-85  "
          >
            {Loading ? "Sending verification code..." : "Create an account"}
          </button>
          <div className="flex space-x-1 text-sm mt-2">
            <p>Already registered ?</p>
            <p
              onClick={() => {
                navigate("/login");
              }}
              className="text-primary cursor-pointer font-semibold"
            >
              Login
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
