import { AiOutlineEye } from "react-icons/ai";
import StatsItem from "../components/statsItem";
import { useGetParams } from "../utils/useGetParams";
import { BsChevronDown } from "react-icons/bs";
import PieChart from "../components/charts/pieChart";
import GroupedBarChart from "../components/charts/groupbarChart";
import HorizontalBarChart from "../components/charts/horizontalBarChart";
import Back from "../components/back";
import { MdOutlineAreaChart } from "react-icons/md";
import { TbClick, TbDatabaseDollar } from "react-icons/tb";

const CampaignReport = () => {
  const { uuid, name } = useGetParams();
  return (
    <div className=" text-dark pb-12 ">
      <Back />
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h1 className="font-bold text-lg">{name} Report</h1>
          <p className="text-gray-500 text-sm">View campaign report below</p>
        </div>
        <button className="flex  items-center">
          From:
          <input type="date" className="form-style me-4 ml-1" />
          to:
          <input type="date" className="form-style  ml-1" />
        </button>
      </div>
      <div className="pt-4">
        <div className="grid grid-cols-4 gap-4">
          <StatsItem icon={<AiOutlineEye />} title="Impressions" value="4007" />
          <StatsItem icon={<MdOutlineAreaChart />} title="Reach" value="2083" />
          <StatsItem icon={<TbClick />} title="Clicks" value="83" />
          <StatsItem
            icon={<TbDatabaseDollar />}
            title="Budget"
            value="TZS 30,000"
          />
        </div>
        <div className="grid grid-cols-12 gap-4  mt-4">
          <div className=" col-span-6 2xl:col-span-3 bg-white p-4 shadow rounded-lg">
            <div className="flex justify-between items-center">
              <div className="space-y-1">
                <h1 className="font-bold text-lg">Locations </h1>
                <p className="text-xs text-muted">
                  Campaign locations distribution{" "}
                </p>
              </div>
            </div>

            <GroupedBarChart
              categories={[
                "Dar es salaam",
                "Morogoro",
                "Mwanza",
                "Mbeya",
                "Dodoma",
                "Ruvuma",
              ]}
              data1={[500, 700, 200, 70, 10, 20]}
              data2={[400, 650, 100, 67, 5, 10]}
              label1={"Impressions"}
              label2={"Reach"}
            />
          </div>
          <div className=" col-span-6 2xl:col-span-3 bg-white p-4 shadow rounded-lg ">
            <div className="flex justify-between items-center">
              <div className="space-y-1">
                <h1 className="font-bold text-lg">Demographics (Age)</h1>
                <p className="text-xs text-muted">
                  Campaign demographics (Age) distribution{" "}
                </p>
              </div>
            </div>
            <GroupedBarChart
              categories={["18-24", "25-34", "35-44", "45-54", "55-64", "65+"]}
              data1={[500, 700, 200, 70, 10, 20]}
              data2={[400, 650, 100, 67, 5, 10]}
              label1={"Impressions"}
              label2={"Reach"}
            />
          </div>
          <div className=" col-span-6 2xl:col-span-3 bg-white shadow p-4  rounded-lg ">
            <div className="space-y-1">
              <h1 className="font-bold text-lg">Placements (impressions)</h1>
              <p className="text-xs text-muted">
                Campaigns reach on different platforms
              </p>
            </div>
            <PieChart data={[158, 80]} labels={["Facebook", "Instagram"]} />
          </div>
          <div className=" col-span-6 2xl:col-span-3 bg-white shadow p-4  rounded-lg ">
            <div className="space-y-1">
              <h1 className="font-bold text-lg">Placements (Reach)</h1>
              <p className="text-xs text-muted">
                Campaigns reach on different platforms
              </p>
            </div>
            <PieChart data={[558, 80]} labels={["Facebook", "Instagram"]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignReport;
